/**
 * [Text]
Normal=#00FF00
Current=#FFFFFF
NormalBG=#000000
SelectedBG=#0000C6
Font=Arial

 */
export const WINAMP_NORMAL = "#00FF00";
export const WINAMP_SELECTED_BG = "#0000C6";
